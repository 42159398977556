.container {
  height: 100%;
  padding-bottom: 50px;
}
.order {
  line-height: 1.15;
  margin-top: 0;
  margin-bottom: 20px;
}
.order__list {
  display: flex;
  flex-direction: column;
}
.order__item {
  line-height: 1.15;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  flex-grow: 1;
}
.order_disclaimer {
  padding: 10px;
  margin-bottom: 15px;
  color: #000;
  font-size: 14px;
  background: #e1e1e1;
  border-radius: 5px;
  text-align: center;
}
.order__disclaimer_title {
  font-size: 24px;
  margin: 20px 0;
  font-weight: 400;
}
.item {
  line-height: 1.15;
  touch-action: manipulation;
  flex-direction: column;
  padding-bottom: 30px;
  display: flex;
}
.order__item_wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.order__item_photo {
  line-height: 1.15;
  touch-action: manipulation;
  height: 80px;
  width: 80px;
  min-width: 60px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 8px;
}
.order__item_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.order__item_info {
  line-height: 1.15;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex-grow: 1;
}
.order__item_title {
  font-size: 17px;
  margin: 0;
  letter-spacing: 0.5px;
  line-height: 22px;
  padding-bottom: 10px;
}
.order__item_subtitle {
  font-size: 15px;
  padding-bottom: 7px;
}
.order__item_options {
  line-height: 1.15;
  touch-action: manipulation;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0;
}
.order__counter {
  line-height: 1.15;
  touch-action: manipulation;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.order__counter_button {
  touch-action: manipulation;
  line-height: 1.15;
  overflow: visible;
  text-transform: none;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: inline-block;
  padding: 0;
  margin: 0;
  outline: none;
  width: 24px;
  height: 24px;
  font-size: 18px;
  border-radius: 6px;
}
.order__counter_quantity {
  line-height: 1.15;
  touch-action: manipulation;
  color: #000;
  font-size: 14px;
  margin: 0 8px;
}
.order__price {
  line-height: 1.15;
  font-size: 14px;
  margin-left: 16px;
  letter-spacing: 0.4px;
}

@media screen and (max-width: 1024px) {
  .order {
    padding: 0 20px 0 20px;
  }
}
