.blockTitle {
  padding-top: 120px;
}
.empty__link {
  margin: 20px 10px;
  width: 100px;
}
.link__backArrow {
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.15;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.link__backArrow span {
  width: 100%;
}
.link__backArrow:hover {
  color: #000;
}
.link__description {
  margin: 0 10px;
}
.order {
  line-height: 1.15;
  margin-top: 0;
  margin-bottom: 20px;
}
.order_disclaimer {
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #000;
  font-size: 14px;
  background: #e1e1e1;
  border-radius: 5px;
  text-align: center;
}
.order__disclaimer_title {
  font-size: 24px;
  margin: 20px 0;
  font-weight: 400;
}
@media screen and (max-width: 1024px) {
  .blockTitle {
    padding: 120px 20px 0 20px;
  }
}
