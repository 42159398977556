.item__option {
    border-radius: 18px;
    border: 1px solid #000;
    font-weight: 400;
    letter-spacing: .2px;
    font-size: 15px;
    padding: 8px 16px 6px;
    margin-right: 6px;
}

.item__option:focus {
    background-color: rgb(0, 0, 0);
    color: white;
    transition: all .25s linear;
    -webkit-tap-highlight-color: rgb(0, 0, 0);
    -webkit-text-fill-color: #ffffff;
}