.header {
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.header a {
  cursor: pointer;
}
.header__logo {
  height: 70px;
  width: 70px;
  display: block;
  /* margin-left: auto;
    margin-right: auto; */
  margin-top: 20px;
  margin-bottom: 40px;
  /* background-color:#000;
    border-radius: 8px; */
}

.header__nav {
  padding-top: 20px;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  gap: 25px;
}

.header__nav a:hover {
  color: #000000;
  /* transition: .2s; */
}

.header__nav_item {
  text-decoration: none;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #13978c;
}

.header__nav_item .header__nav_active {
  text-decoration: none;
  color: #000000;
}

/* корзина - кнопка */
.header_infoBlock {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  touch-action: manipulation;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  top: -8px;
}

.header_cartLink {
  line-height: 1.15;
  touch-action: manipulation;
  background-color: transparent;
  text-decoration: none;
}

.header_cartWrapper {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  touch-action: manipulation;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  padding: 10px 20px;
  border-radius: 8px;
}

@media screen and (max-width: 1220px) {
  .header {
    /* max-width: 1200px; */
    padding: 0 60px;
    transition: 0.5s;
  }
}

@media screen and (max-width: 530px) {
  .header {
    /* max-width: 1200px; */
    padding: 0 25px;
    transition: 0.5s;
  }
}
