.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);

}

.modalActive {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  z-index: 1000;
  
}

.modal__block {
  padding: 40px;
  border-radius: 12px;
  background-color: #fff;
  min-width: 650px;
  max-height: 100%;
  transition: all 0.5s;
}

.modal__content {
  max-width: 1100px;
}

.modal__content_header {
  margin-bottom: 20px;
}

.modal__content_header h1 {
  font-weight: 800;
  text-align: center;
  font-size: 24px;
}

.modal__content_header h2 {
  padding-top: 10px;
  font-weight: 800;
  text-align: center;
  font-size: 18px;
}

.modal__content_main {
  text-align: center;
}

.modal__content_main p {
  padding-bottom: 10px;
  font-size: 16px;
}

.modal__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

/* button */

.modal__agree {
  padding-top: 15px;
  text-align: center;
  max-width: 700px;
  display: block;
  margin: 0 auto;
}

.modal__agree button {
  border-radius: 4px;
  background-color: #5ca1e1;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 32px;
  padding: 16px;
  width: 220px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 36px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, .7);
}

.modal__agree button {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.modal__agree button:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 14px;
  right: -20px;
  transition: 0.5s;
}

.modal__agree button:hover {
  padding-right: 24px;
  padding-left: 8px;
}

.modal__agree button:hover:after {
  opacity: 1;
  right: 10px;
}

.modal__agree div {
  margin-top: 15px;
  font-size: 24px;
}

.modal__agree_btn {
  margin-top: 15px;
}

.modal__block_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal_title {
  font-weight: 400;
  font-size: 22px;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modal_close {
  width: 24px;
  height: 24px;
  fill: #000;
  padding: 4px;
  position: relative;
  bottom: 4px;
  left: 4px;
  border-radius: 50%;
  cursor: pointer;
}

.modal__blockPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 32px 0 0 32px;
}

.modal__blockPrice_price {
  font-weight: 400;
  font-size: 18px;
  margin: 0 16px 0 0;
}

.modal__blockPrice_btn {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  touch-action: manipulation;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  padding: 10px 20px;
  border-radius: 8px;
  opacity: .75;
}

.modal__blockPrice_btn:hover {
  opacity: 1;
  transition: all .25s linear;
}

.modal__blockPrice_inActive {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  touch-action: manipulation;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  padding: 10px 20px;
  border-radius: 8px;
  opacity: .75;
}

@media screen and (max-width: 700px) {
  .modalActive{
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transform: scale(1);
  z-index: 1000;
  overflow: hidden;
  }
  .modal__block {
    
    min-width: 100%;
    max-height: 100%;
    transition: all 0.5s;
  }
  
}
@media screen and (max-width: 530px) {
  .modal__blockPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }
  .modal__blockPrice_price {
    padding-bottom: 10px;
  }
  .modal__item {
    overflow: auto;
  }
}

